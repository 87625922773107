import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"




// import Swiper core and required modules
import SwiperCore, {
  Parallax,Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Parallax,Pagination,Navigation]);


export default function PizarraCNITA() {
  
  return (
  <>
    
  <Swiper 
    //style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}} 
    speed={900} parallax={true} 
    pagination={{"clickable": true}}  
    navigation={true} 
    className="mySwiper swiper-container1"
    >
    <div slot="container-start" 
      className="parallax-bg1" 
      // style={{'backgroundImage': 'url(./assets/CartaNatalFondo.png)'}} 
      data-swiper-parallax="-5%">

    </div>
      <SwiperSlide>
        <div className="title" data-swiper-parallax="-300">Tema Natale</div>
        <div className="subtitle" data-swiper-parallax="-200"></div>
        <div className="text" data-swiper-parallax="-100">
          <p>La lettura del tuo Tema Natale consiste in un incontro nel quale, partendo dalla mia visione, ti trasmetterò la interpretazione del codice astrologico della tua struttura energetica.
            Approfondiremo le tue energie predominanti e molte volte antagoniche del tuo micro mondo interiore. 
            Esploraremo parti sconosciute che ti accompagneranno in un processo di autoconoscimento e autocomprensione
          </p>
        </div>     
      </SwiperSlide>
      <SwiperSlide>
        <div className="title" data-swiper-parallax="-300">Modalità</div>
        <div className="subtitle" data-swiper-parallax="-200"></div>
        <div className="text" data-swiper-parallax="-100">
          <p>La consulta può svolgersi online via zoom o potrebbe essere presenziale.
            La durata può variare tra i 90 e i 120 minuti. 
            Una volta realizzato il pago, ti invierò il link per la video chiamata. 
            Se lo desideri potremo registrare la consulta così potrai vederla altre volte in tranquillità.
          </p>
          </div>
            
      </SwiperSlide>
      <SwiperSlide>
          <div className="title" data-swiper-parallax="-300">Requisiti</div>
          <div className="subtitle" data-swiper-parallax="-200"></div>
          <div className="text" data-swiper-parallax="-100">
          
          <p>Avrò bisogno del tuo nome, della tua data e ora di nascita.
            Tieni in conto che la ora di nascita deve essere precisa, ti consiglio
            comprovarla con il certificato di nascita.</p>

            <p>Il prezzo è di 80 euro e puoi riservare la tua consulta inviando 
              un email a contacto@micromundos.org</p>

            
          </div>
        </SwiperSlide>
        
  </Swiper>

  <img 
    src="./assets/cartamm1.webp" 
    alt="fondo nucleos"
    className="consultas__img"
  />
  </>
  )
}
