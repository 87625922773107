import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
import { ConsultasITA } from '../components/ConsultasITA';
import { IntroduccionITA } from '../components/IntroduccionITA';
import { MainITA } from '../components/MainITA';
import { SobreMiITA } from '../components/SobreMiITA';
import { ContattoITA } from '../components/ContattoITA'
import { AppRouter } from './AppRouter';


export const AppRouterITA = () => {
    return (
        <Router>
            
            <div>
            

                <Switch> 
                    <Route exact path='/astrvivaITA' component={ IntroduccionITA }/>
                    <Route exact path="/consultasITA" component={ ConsultasITA }/>
                    <Route exact path="/aboutITA" component={ SobreMiITA } />
                    <Route exact path="/contactoITA" component={ ContattoITA } />
                    
                    {/*<Route exact path="/privacidadITA" component={ PrivacidadITA } />
                    <Route exact path="/termycondITA" component={ TerminosITA } /> */}
                    
                    <Route exact path="/" component={ AppRouter }/>
                    <Route exact path="/ITA" component={ MainITA }/>

                  <Redirect to='/ITA'/>
                  
                </Switch>
      
            </div>
        </Router>
    )
}