import React from 'react';
import ScrollIntoView from 'react-scroll-into-view';
import { NavbarITA } from './NavbarITA';
import { TemaNatale } from './consultasITA/TemaNatale';
import { RivoluzioneSolare } from './consultasITA/RivoluzioneSolare';
import { SinastriaITA } from './consultasITA/SinastriaITA';
import { Footer } from './Footer';



export const ConsultasITA = () => {
    return (
        <>
        <NavbarITA/>
        <div className='animate__animated animate__fadeIn'>
        
            <div className='consultas__titulo'>
                
                <h4 className="focus-in-contract-bck">SERVIZI</h4>
                    
            </div>
            <div className='consultas__contenedor focus-in-contract-bck'> 

                <div className='consultas__servicio'>
                    <img src='./assets/consultasCN1.webp'
                        className='img1 '
                        alt='consulta1'/>
                    <div className="middle">

                        <ScrollIntoView selector="#temanatale">
                          <button className="mdl-button mdl-js-button mdl-button--raised">
                            Tema Natale
                          </button>
                        </ScrollIntoView>

                    </div>
                </div>
                <div className='consultas__servicio'>
                    <img src='./assets/consultasRV1.webp'
                        className='img2 '
                        alt='consulta2'/>
                    <div className="middle">

                        <ScrollIntoView selector="#rivSolare">
                          <button className="mdl-button mdl-js-button mdl-button--raised">
                          Rivoluzione Solare + Transiti
                          </button>
                        </ScrollIntoView>

                    </div>
                </div>

                <div className='consultas__servicio'>
                    <img src='./assets/consultasS1.webp'
                        className='img3'
                        alt='consulta3'/>
                    <div className="middle">

                            <ScrollIntoView selector="#sinastriaITA">
                              <button className="mdl-button mdl-js-button mdl-button--raised">
                                Sinastria
                              </button>
                            </ScrollIntoView>

                    </div>
                </div>
            </div>
        </div>

        <TemaNatale/>
        
        <RivoluzioneSolare/>
        
        <SinastriaITA/>
        
        <Footer/>
        
        </>
    )
}
