import React from 'react'
import PizarraRS from './PizarraRS'

export const RevolucionSolar = () => {
    return (
        <div id='revSolar' className='contenedor__consulta'>
            <div className='contenedor__consulta'>
                <PizarraRS/>
                
            </div>
        </div>
    )
}
