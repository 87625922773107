import React from 'react'

import { Navbar } from './Navbar'
import { Objeto3d } from './objeto3d/Objeto3d'

export const Main = () => {
         
    return (
        <>
        <Navbar/>
        
            <div className="main-content animate__animated animate__fadeIn fade-in">

                <main className="main__main-content bg-principal ">
                    <div className='main__titulo tracking-in-contract '>
                        <h1>MICROMUNDOS</h1>
                    </div>
                    <Objeto3d className='contacto__objeto3d' />
                    {/* <div className='main__text'>
                        <h3>Hola! Soy {parola}</h3>
                    </div> */} 
                    <div className='main__img360 slide-in-bottom'>
                        <img src="./assets/juega.webp" 
                        alt="360img"/>
                    </div>
                </main>
            </div>
        
        
        </>
        
        
    )
}
