import React from 'react'
import { OrbitControls, Stars } from '@react-three/drei';

export const Lights = () => {

    const FakeSphere = () => {
        return (
          <mesh>
            <sphereBufferGeometry args={[0.8, 30, 30]} attach="geometry" />
            <meshLambertMaterial color={0xff8300} attach="material" />
            
          </mesh>
        );
    }
    return (
        <group>
          <OrbitControls />
          <Stars fade={true}/>
          <ambientLight intensity={0.9} />
          <spotLight intensity={1.12} position={[10, 10, 10]} />
          <FakeSphere />
        </group>
    )
}
