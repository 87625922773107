import React from 'react'
import { Footer } from './Footer'
import { Navbar } from './Navbar'
import { Link} from 'react-router-dom';
import ScrollIntoView from 'react-scroll-into-view';




export const Introduccion = () => {
    return (
        
        <div className='animate__animated animate__fadeIn'>
        <Navbar/>
        <div className='introduccion'>
            <div className="wrapper">
                
                <section id="section01" className="demo">               
                <div className='introduccion__boxflex'>
                <div className='introduccion__titulo introduccion__fixed-bg bg-1'>
                <div className='introduccion__tema text-focus-in'>
                    <h4 className="focus-in-contract-bck">ASTROLOGÍA VIVA</h4>
                </div>
                    <p><span className='introduccion__p1-bold'>La astrología es movimiento</span>, es el movimiento de las estructuras energéticas,
                    <span className='introduccion__p1-bold'> es un lenguaje</span> que sirve para simbolizar y combinar la experiencia de que el universo es una red. 
                    </p>
                    <p>Es una percepción, es percibir la realidad de una cierta manera, es la percepción de cualidades energéticas.
                        El conocimiento de la astrología como herramienta nos permitirá comenzar a <span className='introduccion__p1-bold'>comprender
                        el sonido de nuestra melodía interna</span>. El propósito de la astrología no es el de manipular el destino, sino vivir al ritmo de él, abriéndonos a nuestra esencia, haciendo consiente lo inconsciente
                    </p>
                    <p>
                        Astrología viva, como energía en movimiento, como experiencia en continuo despliegue, en proceso de cambio constante, como <span className='introduccion__p1-bold'>un micro mundo dentro de un sistema en la inmensidad</span> .
                    </p>
                </div>     
                </div>
                    <ScrollIntoView selector='#section02'>
                    <Link>
                        <span></span>
                    </Link>
                    </ScrollIntoView>
                </section>          
                
                <section id="section02" className="demo"> 
                <div className='introduccion__boxflex'>
                <div className='introduccion__titulo introduccion__fixed-bg bg-2'>
                <div className='introduccion__tema'>
                    <h4>La Carta Natal</h4>
                </div>
                    <p>La carta natal es un código matemático, un patron vibratorio que se irá desplegando a lo largo de nuestra existencia y se manifestará de múltiples maneras, formas, variables y matices.
                        Un puzzle, una parte de un entramado energético en continuo movimiento, vivo.
                        Un sistema que interactúa con otros sistemas a través de vínculos y experiencias.</p>
                    <p>La carta natal es la foto del cielo, que capta el orden que tenía nuestro sistema solar en el momento preciso en que nacimos, desde la perspectiva de la astrología, esa toma forma en la tierra, lo que vendría a ser nuestra estructura energética.</p>
                    <p>El sol, la luna y el ascendente serán la forma en que manifieste la energía principal de mi personalidad, un fragmento. La luna en un principio, el sol como búsqueda de mi individuación, mi forma de brillar y ser único, el ascendente será la forma del camino que tomaré como aprendizaje .</p>
                    
                </div>
                </div>
                </section>
                
                <section id="section03" className="demo">
                <div className='introduccion__boxflex'>           
                <div className='introduccion__titulo introduccion__fixed-bg bg-3'>
                <div className='introduccion__tema'>
                    <h4>Los Planetas</h4>
                </div>
                <p>Cada planeta tiene su propia cualidad, su propia vibración energética, siendo corrientes de energía que interactúan entre sí como partes de un sistema.
                        Cada uno de nosotros tiene en la configuración de su carta natal a cada planeta en un lugar especifico, en un signo y en una casa.  </p>
                    <p>Cada planeta tiene su función dentro del sistema, representado por un arquetipo, en modo en que el inconsciente colectivo connota una función energética especifica.
                        Y dentro de uno mismo se organiza ese arquetipo con tales características especificas, como padre, autoridad, madre, etc</p>
                </div>    
                </div>
                </section>
                
                <section id="section04" className="demo">
                <div className='introduccion__boxflex'>            
                <div className='introduccion__titulo introduccion__fixed-bg bg-4'>
                <div className='introduccion__tema'>
                    <h4>La Luna</h4>
                </div>
                    <p>La luna representa la nutrición, la protección, el cuidado, la sustancia vital.
                        La luna es el hábitat emocional donde se conforma nuestra primera identidad.
                        Es nuestra memoria, en donde se generan nuestras pautas emocionales de identificación.</p>
                    <p>Es la energía dominante en un principio de nuestras vidas.                      
                        Nuestro primer campo energético al nacer, nuestra madre, el hogar, la familia, el medio que nos rodea, el ambiente, las matrices holográficas que se manifiestan en red.
                        Es el lugar más cómodo, nuestro refugio.</p>
                    <p>La luna en nuestra carta natal según el signo en el que esté va a tener ciertas cualidades, ciertas características que en principio tendremos de manera inconsciente y mecánica, antes una situación de peligro, temor, volveré a refugiarme</p>
                </div>
                </div>
                </section>

                <section id="section06" className="demo">
                <div className='introduccion__boxflex'>
                <div className='introduccion__titulo introduccion__fixed-bg bg-5'>
                <div className='introduccion__tema'>
                    <h4>Los Signos</h4>
                </div>
                    
                    <p>Los signos son patrones energeticos primarios e indican las cualidades especificas de la experiencia.
                        Un modo para comprender los diferentes patrones es analizarlos en relacion a su modalidad de vibracion (fuego, tierra, agua, aire).
                    </p>
                    
                </div>
                </div>

                </section>

                <section id="section05" className="demo">
                <div className='introduccion__boxflex'>
                <div className='introduccion__titulo introduccion__fixed-bg bg-6'>
                <div className='introduccion__tema'>
                    <h4>Las Casas</h4>
                </div>
                    
                    <p>Las casas son las doce categorías de experiencia.
                        Son el vehículo de distribución de la energía, también los escenarios y los campos de experiencia en donde se manisfestará esa energía.
                        
                    </p>
                    <p>Son las subdivisiones de la rotación diaria de la tierra sobre su propio eje.
                        Describen un determinado conjunto de cirscuntancias y situaciones en la vida de una persona.
                    </p>
                </div>
                </div>
                </section>
                
                <Footer/>

            </div>
        </div>
        
        </div>
        
        
    )
}
