import React from 'react'
import PizarraS from './PizarraS'

export const Sinastria = () => {
    return (
        <div id='sinastria' className='contenedor_consulta'>
            <div className='contenedor__consulta'>
                <PizarraS/>
            </div>
        </div>
    )
}
