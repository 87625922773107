import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"




// import Swiper core and required modules
import SwiperCore, {
  Parallax,Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Parallax,Pagination,Navigation]);


export default function PizarraRS() {
  
  
  
  return (
    <>
    
  <Swiper 
    //style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}} 
    speed={900} parallax={true} 
    pagination={{"clickable": true}}  
    navigation={true} 
    className="mySwiper swiper-container2"
    >
    <div slot="container-start" 
      className="parallax-bg1" 
      //style={{'backgroundImage': 'url(./assets/ene21.png)'}} 
      data-swiper-parallax="-5%">

    </div>
      <SwiperSlide>
        <div className="title" data-swiper-parallax="-300">Revolución Solar / Transitos </div>
        <div className="subtitle" data-swiper-parallax="-200"></div>
        <div className="text" data-swiper-parallax="-100">
          <p>La Revolución solar es una variante anual de nuestra carta y debe leerse en relación con ésta y con todos los tránsitos y ciclos que estén ocurriendo.
                Es la vuelta del sol al mismo punto del nacimiento desde otra experiencia.
                Te recomiendo hacerla cerca de la fecha de tu cumpleaños.</p>
        </div>     
      </SwiperSlide>
      <SwiperSlide>
        <div className="title" data-swiper-parallax="-300">Modalidad</div>
        <div className="subtitle" data-swiper-parallax="-200"></div>
        <div className="text" data-swiper-parallax="-100">
          <p>La entrevista puede ser online via zoom o presencial
              Tiene una duración de 60 minutos.
              Una vez realizado el pago, te enviaré
              el link del enlace para la videollamada.
              Si lo deseas podemos grabarla, y asi podrás verla
              otras veces con más tranquilidad.</p>
          </div>
            
      </SwiperSlide>
      <SwiperSlide>
          <div className="title" data-swiper-parallax="-300">Requisitos</div>
          <div className="subtitle" data-swiper-parallax="-200"></div>
          <div className="text" data-swiper-parallax="-100">
          
          <p>Necesitaré tu nombre, fecha, hora y lugar de nacimiento.
            Tén en cuenta que el horario de nacimiento debe ser
            preciso, te aconsejo mirarlo en  tu certificado de
            nacimiento. </p>
            <p>
            El precio es 65 euros o 6500 AR$. Acepto criptomonedas. 
            Puedes reservar tu consulta
            escribiendo un email a contacto@micromundos.org</p>
          </div>
        </SwiperSlide>
        
  </Swiper>

  <img 
    src="./assets/revolucionsolar1.webp" 
    alt="fondo nucleos"
    className="consultas__img"
  />
    
  </>
  )
}





        
    