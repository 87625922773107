import React, { useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

export const Navbar = () => {

    const [isActive, setisActive] = useState(false);

    const toggleTrueFalse = () => setisActive(!isActive);

    const history = useHistory();

    const handleStart = () => {
        let path = '/'; 
        history.push(path);
    }
    
    return (
        <nav className="navbar">
            <div className='navbar__logo logo'
                  onClick={handleStart}
            >
                <img src="./assets/logo11.webp" 
                        alt="logo"
                        className="logo"
                        min-width={60}
                        min-height={60}
                        
                        />
            </div>
            <div className="navbar__links">
                
                
                { 
                    
                    (isActive === true) &&
                    <div className="navbar__menu animate__animated animate__fadeInDown">
                        
                        <div className="navbar__links-box1">
                            <NavLink 
                                exact
                                className="navbar__link"
                                to='/astrviva'
                            >
                                <p>Astrología viva</p>
                            </NavLink>
                            <NavLink 
                                exact
                                className="navbar__link"
                                to='/consultas'
                            >
                                <p>Consultas</p>
                            </NavLink>
                            <NavLink 
                                exact
                                className="navbar__link"
                                to='/about'
                            >
                                <p>Sobre mi</p>
                            </NavLink>
                            <NavLink 
                                exact
                                className="navbar__link"
                                to='/contacto'
                            >
                                <p>Contacto</p>
                            </NavLink>
                            
                            
                        </div>
                        <div className="navbar__links-box2">
                            <a className="navbar__link" 
                                href="https://www.instagram.com/mi_cro_mon_di">
                                <i className="fab fa-instagram fa-lg">
                                </i>
                            </a>
                            <li className="navbar__link">
                                <p>Esp</p>
                            </li>
                            <NavLink
                                exact 
                                className="navbar__link"
                                to='/ITA'>
                                <p>Ita</p>
                            </NavLink>
                            
                        </div>
                    </div>
                    
                }
                { 
                    (isActive === false) &&
                    <div className="navbar__menu1">
                        
                    </div>
                }
            </div>
            <button 
                    className="navbar__hamburger"
                    onClick={toggleTrueFalse}>
                    <span className="navbar__bar1"></span>
                    <span className="navbar__bar2"></span>
                    <span className="navbar__bar3"></span>
            </button>
            
        </nav>
    )
}
