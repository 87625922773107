import React from 'react'
import { Footer } from './Footer'
import { NavbarITA } from './NavbarITA'

export const SobreMiITA = () => {
  return (
    <>
    <NavbarITA/>

    <section>

    <div className='sobremi__titulo'>

      <h4 className="focus-in-contract-bck">ABOUT ME</h4>

    </div>
    <div className="sobremi__wrapper">
      <div className="sobremi__one"></div>
      
      <div className="sobremi__two">
        <div className="sobremi__flex">
        <p>Ciao!</p>
          <p> Mi chiamo Debora ma preferisco che mi chiamino Deb.</p>
          <p>Ti ringrazio per esserti interessatx in questo spazio che ho creato e che
          va prendendo forma con il tempo e le esperienze.
          </p>
          <p>Micromundos è uno spazio nel quale convergono in maniera transversale l'
           <span>astrologia</span>,  
          l' <span>arte</span> e l' <span>apprendimento</span> libero.
          Micromundos como un micro-mondo, parte di un sistema,
          è uno spazio di accompagnamento per lo sviluppo della tua creatività.
          </p>
          <p> 
          Ho studiato realizzazione scenografica al teatro Colón di Buenos Aires,
          disegno tessile e mapping a Barcelona e ho iniziato a creare spazi
          e scenari effimeri di gioco e di apprendimento libero nelle scuole
          fino a quando iniziò la pandemia.
          Mi sono formata in <span>astrologia psicologica</span> presso
          Cosmograma, a Barcellona, specializzandomi con un postgrado con Silvia Neira 
          in Argentina.
          
          Attualmente sto approfondendo le mie conoscenze 
          dell' educazione libera e sulla pedagogia sistemica.</p>
        
        </div>
      </div>
      <div className="sobremi__three"></div>
      <div className="sobremi__four">
        <p>Sono curiosa, autodidatta, amo indagare, studiare e creare.
             Mi sento a mio agio nell'esprimermi con immagini e nel pensare a modo di mappa
             mentale.
             Indago me stessa, mi interessano le forme di auto conoscenza, i legami tra le persone,
             la neurodiversità, i pianeti transpersonali, i sistemi, la psicobiologia, l'arte e la creatività.</p>
            
            <p>Sono madre di Olivia e Luca, due piccoli grandi alchimisti, che giorno dopo giorno mi
             trasformarmano e che ogni giorno mi procurano un biglietto di sola andata al presente.
             Crescendo con loro, mi mostrano le mie
             forme di vibrazione, alte e basse, affinando le note musicali che intona la mia melodia.</p>
             
             <p>Dalle mie ricerche nasce Micromundos, in cui faccio 
             una sintesi di tutte le mie esperienze in continua
             trasformazione, nel modo più trasversale, come sono io, sistemica, 
             artistica, visiva, neurodiversa, astrologica,
             strutturata, ipersensibile, idealista, autonoma e alchimista, 
             per poi condividere i risultati nel modo più autentico possibile, con lo
             scopo di accompagnare e condividere i processi di adulti e bambini.
             Accompagnando e a sua volta crescendo con l'incontro...
             Senza essere guru, senza promuovere ideologie, ma piuttosto 
             focalizzando e promuovendo
             l'unicità del nostro micromondo, sia il tuo come il mio.</p> 
      </div>
      {/* <div className="sobremi__four2">
      <p>Dalle mie ricerche sulle domande che mi pongo, nasce Micromundos, in cui faccio 
        una sintesi di tutte le mie esperienze in continua
             trasformazione, nel modo più trasversale, come sono io, sistemico, 
             artistico, visivo, neurodiverso, astrologico,
             strutturato, ipersensibile, idealista, autonomo e alchimista, 
             per poi condividere i risultati nel modo più autentico possibile, con lo
             scopo di accompagnare e condividere i processi di adulti e bambini.
             Accompagnando e a sua volta crescendo con l'incontro...
             Senza essere una guru, senza promuovere specifiche i ideologie, ma piuttosto 
             focalizzando e promuovendo
             l'unicità del nostro micromondo, il tuo e il mio.</p>     
      </div> */}
      <div className="sobremi__five"></div>
      <div className="sobremi__six">
      <p>Impararando in movimento e in viaggio, tra Argentina, Spagna e
             Italia. Alcune volte al nord e altre volte al sud.
             Ora dall'altro lato della mappa e anche dall'altro lato del
             tema natale, inizia un nuovo ciclo, benvenut.</p>
      </div>
    </div>

  
    </section>
    <Footer/>
    </>
  )
}