import React from 'react'
import { Footer } from './Footer'
import { Navbar } from './Navbar'

export const SobreMi = () => {
  return (

    <>
    <Navbar/>

    <section>
    <div className='sobremi__titulo'>

      <h4 className="focus-in-contract-bck">SOBRE MI</h4>

    </div>
    <div className="sobremi__wrapper">

      <div className="sobremi__one fade-in"></div>
      
      <div className="sobremi__two fade-in">
        <div className="sobremi__flex">
        <p>Hola!</p>

        <p>Mi nombre es Debora, aunque prefiero Deb.</p>
            <p>
            En primer lugar, agradecerte que te hayas interesado en este espacio que he creado y que va
            tomando forma con el tiempo y con las experiencias.</p>
            <p>Micromundos es un espacio en el que conviven y convergen de manera transversal la <span>astrología</span>,  
            el <span>arte</span>, y el <span>aprendizaje</span> libre.
            Micromundos como un micro-mundo parte de un sistema,
            un espacio de acompañamiento en el desarrollo y despliegue de tu creatividad.</p>
        <p>
          Estudié Realización escenográfica en el Teatro Colón de Buenos Aires, Diseño textil y Mapping en Barcelona 
          y empece a crear espacios efimeros lúdicos y de aprendizaje en escuelas hasta que empezó la pandemia.
          Me formé en <span>astrología psicológica</span> en Cosmograma en Barcelona, y cursé postgardos con Silvia Neira en Argentina.
          En estos momentos en proceso de formación en educación libre y pedagogía sistémica.</p>
        
        </div>
      </div>
      <div className="sobremi__three"></div>
      <div className="sobremi__four">
      <p>Soy curiosa, autodidacta, me encanta investigar, estudiar y crear. Me
            siento cómoda expresandome con imágenes y pensando a modo de mapa
            mental.
            Me investigo, me interesan las formas de autoconocimiento, los vinculos entre las personas,
            la neurodiversidad, los planetas transpersonales, los sistemas, la psicobiología, el arte y la creatividad.</p>
            
            <p>Soy mamá de Olivia y Luca, dos pequeños grandes, que día a día me
            transforman y que en su momento me sacaron un billete de sólo ída al presente.
            Creciendo conjuntamente con ellxs, son quienes me muestran mis mejores y mis peores
            formas de vibración, como un diapason que afinan mi melodía.</p>

            <p>De mis búsquedas a preguntas que me hago, surge Micromundos en el que hago 
              síntesis de todas mis experiencias en constante
            transformación, de la manera más transversal, de manera sistémica, artística, 
            visual, neurodiversa, astrológica,
            estructurada, hipersensible, idealista y experimental, para así poder 
            compartirlas de la manera más auténtica posible, con la
            finalidad de acompañar y compartir procesos tanto de adultxs como de niñxs.

            Acompañando y a su vez creciendo en el encuentro.
            Sin ser gurú, sino más bien haciendo foco y potenciando la unicidad de nuestro micromundo, tanto el tuyo como el mío.</p> 
      </div>
      {/* <div className="sobremi__four2">
      <p>De mis búsquedas, surge micromundos en el que hago síntesis de todas mis experiencias en constante
            transformación, de la manera más transversal, de manera sistémica, artística, visual, neurodiversa, astrológica,
            estructurada, hipersensible, idealista y experimental, para así poder compartirlas de la manera más auténtica posible, con la
            finalidad de acompañar y compartir procesos tanto de adultxs como de niñxs.

            Acompañando y a su vez creciendo en el encuentro.
            Sin ser gurú, sino más bien haciendo foco y potenciando la unicidad de nuestro micromundo, tanto el tuyo como el mío.</p>     
      </div> */}
      <div className="sobremi__five"></div>
      <div className="sobremi__six">
      <p>Aprendiendo en movimiento y en viaje, entre Argentina, España e
            Italia. A veces en el norte y a veces en el sur.
            Ahora del otro lado del mapa y también del otro lado de la
            carta natal, comienza un nuevo ciclo, 
            Bienvenidx!.</p>


            <p>Buscando, me encuentro.
            </p>
      </div>
    </div>

    {/* <div className='sobremi__banner' >   
    </div>

    <div className='sobremi__titulo'>
      <h1>SOBRE MI</h1>
    </div>
    <div className='sobremi_texto'>
      <p>Hola! mi nombre es Debora, aunque prefiero Deb. en primer lugar, agradecerte que te hayas 
        interesado en este espacio que he creado y que va tomando forma con el tiempo y con las experiencias.</p>
      <p>Micromundos es un espacio en el que conviven y convergen de manera transversal la astrología, el arte, 
        y el aprendizaje alternativo. micromundos como un micro -mundo parte de un sistema. 
        un espacio de acompañamiento en el desarrollo y despilegue de tu creatividad de manera autónoma. 
        me formé en astrología psicologica en Cosmograma en Barcelona, y con Silvia Neira, en Argentina. 
        En la creación de espacios y escenarios efímeros de juego y de aprendizaje alternativo, con Createctura, 
        en el que el desarrollo de la creatividad es el centro de atención . estudie realización escenográfica en el 
        teatro colón, mapping y diseño textil en Barcelona. formación en proceso de pedagogía sistemica. 
        antes de ser mamá trabajaba como técnica de vestuario y escenografia en teatro y televisión. 
        soy curiosa, autodidacta, me encanta investigar, estudiar y crear. me siento cómoda expresandome con 
        imágenes y pensando a modo de mapa mental. comntinuamente estudiando e investigando maneras de autoconocimiento,
         la neurodiversidad y las formas de creatividad.</p>
      <p>Soy mamá de Olivia y Luca, dos alquimistas enanos, que día a día me transforman y que en su momento 
        me sacaron un billete de sólo ída al presente.
        ser madre hace que me mire al espejo todo el tiempo creciendo conjuntamente con ellxs, esto me ayuda 
        a ver mis mejores y mis peores formas de vibración, vendrian a ser ese diapason que afinan mi melodía.</p>
      <p>De mis búsquedas a preguntas que me hago, surge micromundos en el que hago síntesis de todas mis experiencias 
        en constante transformación, de la manera más transversal, como lo soy yo, de manera sistémica, artística,visual, 
        neurodiversa, astrológica, estructurada, hipersensible, idealista, autonóma y alquímica, para así poder 
        compartirlas de la manera más auténtica posible, con la finalidad de acompañar y compartir procesos tanto 
        adultxs como a niños. acompañando y a su vez creciendo en el encuentro.. sin ser gurú de ideologias, sino más bien 
        haciendo foco y potenciando la unicidad de nuestro micromundo, tanto el tuyo como el mío.</p>
      <p>Aprendiendo en moviemiento y en viaje, entre argentina, españa e italia. a veces en el norte y a veces en el sur. 
        ahora del otro lado del mapa y también del otro lado de la carta natal, comienza un nuevo ciclo, bienvenidx</p>
    </div> */}
    </section>
    <Footer/>
    </>
  )
}
