import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"




// import Swiper core and required modules
import SwiperCore, {
  Parallax,Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Parallax,Pagination,Navigation]);


export default function PizarraSITA() {
  
  
  
  return (
    <>
    
  <Swiper 
    //style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}} 
    speed={900} parallax={true} 
    pagination={{"clickable": true}}  
    navigation={true} 
    className="mySwiper swiper-container3"
    >
    <div slot="container-start" 
      className="parallax-bg1" 
      //style={{'backgroundImage': 'url(./assets/ene41.png)'}} 
      data-swiper-parallax="-5%">

    </div>
      <SwiperSlide>
        <div className="title" data-swiper-parallax="-300">Sinastria</div>
        <div className="subtitle" data-swiper-parallax="-200"></div>
        <div className="text" data-swiper-parallax="-100">
          <p>La Sinastria è la combinazione di due strutture energetiche.
            Ogni Tema Natale rappresenta un campo energetico che allo stesso tempo si relaziona con altri,
              (Mamma e figli - partner - amici ecc) formando una rete vincolare.</p>
        </div>     
      </SwiperSlide>
      <SwiperSlide>
        <div className="title" data-swiper-parallax="-300">Modalità</div>
        <div className="subtitle" data-swiper-parallax="-200"></div>
        <div className="text" data-swiper-parallax="-100">
        <p>La consulta può svolgersi online via zoom o potrebbe essere presenziale.
            La durata è di circa 120 minuti. 
            Una volta realizzato il pago, ti invierò il link per la video chiamata. 
            Se lo desideri potremo registrare la consulta così potrai vederla altre volte in tranquillità.
          </p>
          </div>
            
      </SwiperSlide>
      <SwiperSlide>
          <div className="title" data-swiper-parallax="-300">Requisiti</div>
          <div className="subtitle" data-swiper-parallax="-200"></div>
          <div className="text" data-swiper-parallax="-100">
          
          <p>Avrò bisogno del tuo nome, della tua data e ora di nascita.
            Tieni in conto che la ora di nascita deve essere precisa, ti consiglio
            comprovarla con il certificato di nascita.</p>

            <p>Il prezzo è di 150 euro e puoi riservare la tua consulta inviando 
              un email a contacto@micromundos.org</p>
          </div>
        </SwiperSlide>
        
  </Swiper>
    
  <img 
    src="./assets/sinastria1.webp" 
    alt="fondo nucleos"
    className="consultas__img"
  />
  </>
  )
}