import React from 'react';
import ReactDOM from 'react-dom';

import { MicromundosApp } from './MicromundosApp';
import './styles/styles.scss'



ReactDOM.render(

    <MicromundosApp />,

  document.getElementById('root')
  
);

