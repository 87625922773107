import React from 'react'
import PizarraCNITA from './PizarraCNITA'


export const TemaNatale = () => {
    return (
        <div id='temanatale' className='contenedor__consulta'>
            <div className='contenedor__consulta'>
                <PizarraCNITA/>
            </div>
        </div>
    )
}
