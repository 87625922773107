import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import { NavbarITA } from './NavbarITA';
import { Footer } from './Footer';







export const ContattoITA = () => {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

  //const [bool, setBool] = useState(false);
  
  // handle inputs
  const handleName = (e)=>{
      setName(e.target.value) 
  }
  
  const handleEmail = (e)=>{
      setEmail(e.target.value)
  }
  
  const handleMessage = (e)=>{
      setMessage(e.target.value)
  }

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_vsogz86', 'template_5l7bzif', form.current, 'user_OLaIICeyjlNW8gDREmruP')
      .then((result) => {
          alert('Messaggio inviato correttamente');
          
      }, (error) => {
          alert(error.message);
      });
      e.target.reset();
    }
  
  
  // const formSubmit= async (e)=>{
  // e.preventDefault();
  
  // try {
      
  // let data = {
  //     name,
  //     email,
  //     message
  // }
  
  // setBool(true);
  
  // const res = await axios.post(`/api/contact`, data);
  
  // if(name.length===0 || email.length===0 || message.length===0){

  //   setBool(false);
  
  // }
  
  
  // else if(res.status===200){
  //   setBool(false);
  //   setName('');
  //   setEmail('');
  //   setMessage('')
  
  // }
  
  // } catch (err) {
  //    console.log(err); 
  // }
  
  //}    
    return (
      <>
      <NavbarITA/>
      
      <div className='contacto__banner fade-in' >
        
      </div>
      <div className="contacto__container fade-in">
        {/* <Objeto3d className='contacto__objeto3d' /> */}
        <div className='contacto__2boxes'>
          <div className='contacto__links'>
            <h2 className="focus-in-contract-bck">CONTATTI</h2>
            <hr/>
            
            <p><span><i className="far fa-envelope fa-lg"></i></span>  Email</p>
            <p>contacto@micromundos.org</p>
            <hr/>
            <p><span><i className="fab fa-instagram fa-lg"></i></span>  Instagram</p>
            <p>@mi_cro_mon_di</p>
            <hr/>

          </div>
          <div className="contacto__texto">
            <form  ref={form} onSubmit={sendEmail} >
              <div className='campo1'>
                <h2 className="focus-in-contract-bck">SCRIVI UN MESSAGGIO</h2>
                <p>Se vuoi realizzare una consulta, puoi scrivemi con questo formulario</p>
              </div>
            <div className='campo'>

              <label className='campo__label'>Nome</label>
              <input
                required 
                type="text" 
                className='campo__field'
                onChange={handleName}
                value={name}
                name='name'

                />
            </div>
            
            <div className='campo'>
              <label className='campo__label'>Email</label>
              <input 
                required
                className='campo__field'
                type="email" 
                onChange={handleEmail}
                value={email}
                name='email'
              />
            </div>
            <div className='campo'>
              <label className='campo__label'>Messaggio</label>
              <textarea
                required
                className='campo__field'
                rows="7"
                type="text" 
                onChange={handleMessage}
                value={message}
                name='message'
                
              />
            </div>

              <div className="send-btn">
                <button type="submit" value="Send">Inviare </button>
              </div>

          </form>
          </div>
        </div>
      </div>
      <br/>
      <Footer/>
      <script type="text/javascript"
        src="https://cdn.jsdelivr.net/npm/@emailjs/browser@3/dist/email.min.js">
      </script>
      <script type="text/javascript">
      (function(){
       emailjs.init("user_OLaIICeyjlNW8gDREmruP")
      })();
    </script>
      </>
  )
}

export default ContattoITA
