import React from 'react';
import { Canvas } from "@react-three/fiber";
import { Cubos } from './Cubes/Cubos';

import { Escenario } from './Environment/Escenario';
import { Lights } from './Lights/Lights';



export const Objeto3d = () => {
    return (
        
        <div className='contenedor__3d'>
          <Canvas >
            <Cubos/>
            <Lights/>
            <Escenario/>
          </Canvas>
        </div>
    )
}