import React from 'react'
import { Footer } from './Footer'
import { Navbar } from './Navbar'

export const Terminos = () => {
    return (
        <>
        <Navbar/>
        <div className='contacto__banner fade-in' >
            <div className='contacto__contenido-banner'></div>
        </div>
        <section className='legal__texto fade-in'>
            <h3>Introducción</h3>
            <p>El presente documento incluye las condiciones de uso del sitio web y servicios de MICROMUNDOS. 
                Adicionalmente, presentamos nuestras Políticas de Privacidad donde detallamos nuestras prácticas de manejo de información.</p>
            <h3>Aceptación de las condiciones</h3>
            <p>Antes de ingresar al Sitio micromundos.org o cualquiera de sus enlaces 'El Sitio', todo usuario debe leer, comprender y aceptar los términos y condiciones de uso, 
                en adelante mencionadas como 'Condiciones'.
                Al momento de ingresar y navegar por el Sitio, el usuario quedaría sujeto a estas Condiciones que, junto con nuestras Polí­ticas de Privacidad, 
                rigen nuestra relación con el usuario.
                El usuario debería ingresar al sitio una vez leídas y aceptadas las Condiciones.
                El usuario acepta que MICROMUNDOS utilice la información que comparta según lo establecido en nuestras Polí­ticas de Privacidad en  https://www.micromundos.org/privacidad/, anexas al sitio.</p>
            
            <h3>Propiedad intelectual de MICROMUNDOS</h3>
            <p>Las marcas, logos, contenido, material publicado, imágenes y otros contenidos de MICROMUNDOS incluidos en los Servicios están protegidos por derechos de propiedad intelectual 
                sobre estos materiales. Queda prohibido utilizar el contenido de nuestro Sitio a menos que se solicite permiso al dueño. Queda también prohibido usar las marcas y logos 
                de MICROMUNDOS incluidos en los Servicios sin nuestro consentimiento; además de retirar, oscurecer o alterar el texto o avisos incluidos en los Materiales de MICROMUNDOS.
                
                Se prohí­be utilizar los materiales mencionados para armar cualquier base de datos o utilizarlos sin previo aviso para cualquier otra finalidad. </p>
            <h3>Cambios</h3>
            <p>MICROMUNDOS se reserva el derecho de modificar o reemplazar cualquier parte de estas Condiciones. 
                Es su responsabilidad revisarlos periódicamente para identificar cambios. Su navegación dentro del Sitio luego de la publicación de los cambios supondrá la aceptación de 
                los mismos. Además, MICROMUNDOS podría incluir nuevos servicios o funciones en el futuro. De ser el caso, tales servicios o funciones se verán sujetos a los términos y 
                condiciones especificados en estas Condiciones.

                En caso de tener alguna duda o consulta podrá comunicarse con nosotros vía el siguiente email: contacto@micromundos.org

                Última actualización: 01 de septiembre de 2021. La misma sustituye a cualquiera de las versiones anteriores.</p>
            
        </section>
        <Footer/>
        </>
    )
}
