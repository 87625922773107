import React from 'react'
import PizarraCN from './PizarraCN'

export const CartaNatal = () => {
    return (
        <div id='cartanatal' className='contenedor__consulta'>
            <div className='contenedor__consulta'>
                <PizarraCN/>
                
            </div>
        </div>
    ) 
}
