import React, {
    useMemo,
    useRef,
    useState,
    useEffect,
    useCallback
  } from "react";
  import { random } from "lodash";
  import { useFrame } from "@react-three/fiber";

export const Cube = () => {

    const mesh = useRef();
    const time = useRef(0);

    const [isHovered, setIsHovered] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const isActiveRef = useRef(isActive);

    // position
    const position = useMemo(() => {
      return [random(-4, 4, true), random(-2, 2, true), random(-3, 3, true)];
    }, []);

    // random time mod factor
    const timeMod = useMemo(() => random(0.1, 4, true), []);

    // color

    //const randomColor = Math.floor(Math.random()*16777215).toString(16);
    //console.log('0x' + randomColor)
    //if is hovered is blue 173f5f
    
    //Funcion para obtener string del color anaranjado #ffxx00
    // const letters = '0123456789ABCDEF';
    // var colorRandom = '';
    // for (var i = 0; i < 2; i++) {
    //   colorRandom += letters[Math.floor(Math.random() * 16)];
    // }
    const colores = [ '#04fecd', '#fbd00a', '#f6a015', '#c36af8','#ddfe58','#8cff96', '#5ff90d', '#ebfc4f', '#f279a9', '#f571b9', '#28e2d1', '#54d8ff', '#f9d51d', '#df6f0c','#1ecbf9','#40fa08']
    const colorRandom = colores[Math.floor(Math.random()*colores.length)];
    
    
    const color = isHovered ? colorRandom : (isActive ? 0xf9ba0f : colorRandom);

    //useEffect of the activeState
    useEffect(() => {
      isActiveRef.current = isActive;
    }, [isActive]);

    // raf loop
    useFrame(() => {
      mesh.current.rotation.y += 0.01 * timeMod;
      if (isActiveRef.current) {
        time.current += 0.03;
        mesh.current.position.y = position[1] + Math.sin(time.current) * 0.4;
      }
    });

    // Events
    const onHover = useCallback(
      (e, value) => {
        e.stopPropagation();
        setIsHovered(value);
      },
      [setIsHovered]
    );

    const onClick = useCallback(
      e => {
        e.stopPropagation();
        setIsActive(v => !v);
      },
      [setIsActive]
    );

    return (
        <mesh
          ref={mesh}
          position={position}
          onClick={e => onClick(e)}
          onPointerOver={e => onHover(e, true)}
          onPointerOut={e => onHover(e, false)}
        >
          <sphereBufferGeometry attach="geometry" args={[0.15, 32, 16]} />
          <meshStandardMaterial
            attach="material"
            color={color}
            roughness={0.6}
            metalness={0.1}
          />
    </mesh>
    )
}
