import React from 'react';
import { Footer } from './Footer';
import { NavbarITA } from './NavbarITA';
import ScrollIntoView from 'react-scroll-into-view';
import { Link} from 'react-router-dom';



export const IntroduccionITA = () => {
    return (

        <div className='animate__animated animate__fadeIn'>
        <NavbarITA/>
        <div className='introduccion'>
            <div className="wrapper">
                
                
                <section id="section01" className="demo">               
                <div className='introduccion__boxflex'>
                <div className='introduccion__titulo introduccion__fixed-bg bg-1'>
                <div className='introduccion__tema text-focus-in'>
                    <h4 className="focus-in-contract-bck">ASTROLOGIA VIVA</h4>
                </div>
                    <p><span className='introduccion__p1-bold'>L'astrologia è movimento</span>, è il movimento di strutture energetiche,
                    <span className='introduccion__p1-bold'> è un linguaggio</span> la cui esperienza raccoglie e simbolizza che l'universo sia una rete.
                    </p>
                    <p>È una percezione, è percepire la realtà in una determinata maniera, è la percezione delle qualità energetiche.
                        Il conoscimento dell'astrologia è un ricorso che ci permette cominciare a far <span className='introduccion__p1-bold'>comprendere
                        il suono della nostra melodia interna</span>. Il proposito dell'astrologia no è manipolare il destino ma vivere al suo ritmo, aprendo la nostra essenza,
                        transformando in cosciente lo incosciente.
                    </p>
                    <p>
                        Astrologia viva, come energia in movimento, come esperienza in continua espansione in un processo di trasformazione continuo e costante, come <span className='introduccion__p1-bold'>un micro mondo dentro un sistema nell'immensità</span> .
                    </p>
                </div>     
                </div>
                    <ScrollIntoView selector='#section02'>
                    <Link>
                        <span></span>
                    </Link>
                    </ScrollIntoView>
                </section>
                
                <section id="section02" className="demo"> 
                <div className='introduccion__boxflex'>
                <div className='introduccion__titulo introduccion__fixed-bg bg-2'>
                <div className='introduccion__tema'>
                    <h4>Il Tema Natale</h4>
                </div>
                    <p>Il tema natale è un codice matematico, un modello vibratorio che si svilupperà durante la nostra esistenza e che si manifesterà in molteplici maniere, forme, variabili e sfumature.
                        Un puzzle, parte di una rete, una struttura energetica in continuo movimento, viva.
                        Un sistema che interagisce con altri sistemi attraverso vincoli ed esperienze.</p>
                    <p>Il tema natale è la foto del cielo che percepisce la disposizione del nostro sistema solare al momento esatto della nostra nascita. 
                        Dalla prospettiva dell'astrologia questa foto prende forma sulla Terra e rappresenterà la nostra struttura energetica.</p>
                    <p>Il sole, la luna e l'ascendente saranno i principali frammenti con i quali si manifesterà l` energia della nostra personalità. In una fase d’inizio sarà quella del segno lunare. 
                        Il sole sarà chi guiderà la ricerca della mia individualità, della forma ùnica e personale di essere. 
                        Finalmente l'ascendente sarà il sentiero che seguirò come apprendimento durante la vita (Destino).</p>
                    
                </div>
                </div>
                </section>
                
                
                
                
                <section id="section03" className="demo">
                <div className='introduccion__boxflex'>           
                <div className='introduccion__titulo introduccion__fixed-bg bg-3'>
                <div className='introduccion__tema'>
                    <h4>I Pianeti</h4>
                </div>
                <p>Ogni pianeta ha la sue proprie qualità e la sua vibrazione energetica che si manifestano come correnti d'energia e che interagiscono tra di loro.
                        Ognuno di noi ha nel suo tema natale una configurazione nella quale dove ogni pianeta ha una posizione specifica, in un segno e in una casa.
                    </p>
                    <p>Ogni pianeta ha una funzione nel sistema, rappresentando un archetipo che svolge una funzione. 
                            Le caratteristiche di ogni archetipo si organizzano con tali caratteristiche specifiche (del padre, dell'autorità, della madre ecc.).
                    </p>
                    
                </div>    
                </div>
                </section>
                
                <section id="section04" className="demo">
                <div className='introduccion__boxflex'>            
                <div className='introduccion__titulo introduccion__fixed-bg bg-4'>
                <div className='introduccion__tema'>
                    <h4>La Luna</h4>
                </div>
                    <p>La Luna rappresenta la nutrizione, la protezione, la cura e l'essenza vitale.
                        La luna è l' habitat emozionale dove si conforma la nostra prima Identità.
                        È la nostra memoria, con la quale si generano i modelli emozionali d'identificazione.
                    </p>
                    <p>È l'energia dominante al inizio della nostra vita.
                        Il nostro primo campo energetico alla nascita, la nostra mamma, la casa, la famiglia, le situazioni che ci circondano e le matrici olografiche che si manifestano in rete.
                        È il luogo in cui siamo più comodi, più sicuri, la luna è il nostro rifugio.
                    </p>
                    <p>La luna nel tema natale avrà determinate caratteristiche dipendendo in che segno sia ubicata. 
                        Certe caratteristiche che in un primo momento saranno incoscienti e meccaniche.
                        D'avanti a situazioni di pericolo, timore torniamo al rufugio.</p>
                </div>
                </div>
                </section>

                <section id="section06" className="demo">
                <div className='introduccion__boxflex'>
                <div className='introduccion__titulo introduccion__fixed-bg bg-5'>
                <div className='introduccion__tema'>
                    <h4>I Segni Zodiacali</h4>
                </div>
                    
                    <p>I segni zodiacali sono modelli energetici primari e indicano le qualità specifiche dell'esperienza.
                         Un modo per comprendere le relative strutture consiste in analizzare i segni in relazione alla 
                         loro modalità vibratoria (fuoco, terra, acqua, aria).  </p>
                    
                </div>
                </div>

                </section>
                
                <section id="section05" className="demo">
                <div className='introduccion__boxflex'>
                <div className='introduccion__titulo introduccion__fixed-bg bg-6'>
                <div className='introduccion__tema'>
                    <h4>Le Case</h4>
                </div>
                    
                    <p>Le case rappresentano le dodici categorie dell'esperienza.
                        Sono il mezzo con cui si distribuisce l'energia, sono gli scenari dell' esperienza.
                    </p>
                    <p>Sono le frazioni della rotazione diaria della Terra sul proprio asse terrestre.
                        Descrivono un determinato insieme di circostanze e situazioni nella vita di una persona.
                    </p>
                </div>
                </div>
                </section>

                <Footer/>

            </div>
        </div>
        
        </div>
    )
}
