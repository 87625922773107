import React from 'react';
import PizarraSITA from './PizarraSITA';


export const SinastriaITA = () => {
    return (
        <div id='sinastriaITA' className='contenedor_consulta'>
            
            <div className='contenedor__consulta'>
                <PizarraSITA/>
            </div>
            
        </div>
    )
}
