import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"




// import Swiper core and required modules
import SwiperCore, {
  Parallax,Pagination,Navigation
} from 'swiper/core';

// install Swiper modules
SwiperCore.use([Parallax,Pagination,Navigation]);


export default function PizarraCN() {
  
  
  return (
  <>
    
  <Swiper 
    //style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}} 
    speed={900} parallax={true} 
    pagination={{"clickable": true}}  
    navigation={true} 
    className="mySwiper swiper-container1"
    >
    <div slot="container-start" 
      className="parallax-bg1" 
      // style={{'backgroundImage': 'url(./assets/CartaNatalFondo.png)'}} 
      data-swiper-parallax="-5%">

    </div>
      <SwiperSlide>
        <div className="title" data-swiper-parallax="-300">Carta Natal</div>
        <div className="subtitle" data-swiper-parallax="-200"></div>
        <div className="text" data-swiper-parallax="-100">
          <p>La lectura de tu carta natal consiste en un encuentro en el que, 
            desde mi mirada, te transmitiré la interpretación del código astrológico
            de tu estructura energetica.
            Profundizaremos sobre tus energías predominantes y muchas veces
              antágonicas de tu micromundo interior.
          Haremos foco en las partes
            desconocidas por ti, que te acompañen en
            un proceso de autoncocimiento y de autocomprensión.</p>
        </div>     
      </SwiperSlide>
      <SwiperSlide>
        <div className="title" data-swiper-parallax="-300">Modalidad</div>
        <div className="subtitle" data-swiper-parallax="-200"></div>
        <div className="text" data-swiper-parallax="-100">
          <p>La entrevista puede ser online via zoom o presencial
              Tiene una duración de aproximadamente unos 90 / 120 minutos.
              Una vez realizado el pago, te enviaré
              el link del enlace para la videollamada.
              Si lo deseas podemos grabarla, y asi podrás verla
              otras veces con más tranquilidad.</p>
          </div>
            
      </SwiperSlide>
      <SwiperSlide>
          <div className="title" data-swiper-parallax="-300">Requisitos</div>
          <div className="subtitle" data-swiper-parallax="-200"></div>
          <div className="text" data-swiper-parallax="-100">
          
          <p>Necesitaré tu nombre, fecha, hora y lugar de nacimiento.
            Tén en cuenta que el horario de nacimiento debe ser
            preciso, te aconsejo mirarlo en  tu certificado de
            nacimiento.</p>
            <p>
            El precio es 80 euros o 8000 AR$. Acepto criptomonedas.
            Puedes reservar tu consulta
            escribiendo un email a contacto@micromundos.org</p>
          </div>
        </SwiperSlide>
        
  </Swiper>

  <img 
    src="./assets/cartamm1.webp" 
    alt="fondo nucleos"
    className="consultas__img"
  />
  </>
  )
}





        
    