import React from 'react'
import { Link} from 'react-router-dom';

export const Footer = () => {

    // scroller.scrollTo('myScrollToElement', {
    //     duration: 1500,
    //     delay: 100,
    //     smooth: true,
    //     containerId: 'ContainerElementID',
    //     offset: 50, // Scrolls to element + 50 pixels down the page
        
    //   })
    //const [isActive, setisActive] = useState(false);

    // const history = useHistory();

    // const handleStart = () => {
    //     let path = '/'; 
    //     history.push(path);
    // }
    return (
        
        <div className='footer'>
            <div className="item1">
                <div className='footer__campo'>
                    
                    <img src="./assets/LOGO12.webp" 
                        alt="logo"
                        className="footer_logo1"
                        min-width={60}
                        min-height={60}
                    />
                    <p>MICROMUNDOS®</p>
                    
                </div>
            </div>

            <nav className="item2">
                
                <div className='footer_flex'
                    // onClick={handleStart}
                    >
                    <Link exact to='/astrviva'>Astrologia viva</Link>
                    <Link exact to='/about'>Sobre mi</Link>                  
                    <Link exact to='/consultas'>Carta Natal</Link>                  
                    <Link exact to='/consultas'>Revolución Solar</Link>                  
                    <Link exact to='/consultas'>Sinastría</Link>                  
                               
                </div>

                <div className='footer_flex'>
                    
                    <Link exact to='/astrviva'>Las lunas</Link>                   
                    <Link exact to='/astrviva'>Los planetas</Link>
                    <Link exact to='/astrviva'>Las casas</Link>
                    <Link exact to='/astrviva'>Los signos</Link>
                    <Link exact to='/contacto'>Contacto</Link> 
                </div>

            </nav>
            
            <div className="item4">
                <div className='footer__campo1'>
                    <p><span><i className="far fa-envelope fa-lg color"></i></span>  contacto@micromundos.org</p>
                    <p><span><i className="fab fa-instagram fa-lg color"></i></span>  @mi_cro_mon_di</p>
                </div>
            </div>

            <div className="item3">
                <div className='footer__campo'>
                    <p>Web Design: Debora del Carril</p>
                    <p>Web Development: Vincenzo Delre</p>
                </div>
                <div className='footer__campo'>
                    <p>2022 | MICROMUNDOS®</p>
                </div>
                <div className='footer__campo'>
                    <Link exact to='/termycond'>Términos y Condiciones</Link>                   
                    <Link exact to='/privacidad'>Política de Privacidad </Link>
                </div>
            </div>
            
           
        </div>
        
    )
}
