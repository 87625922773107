import React from 'react';
import ScrollIntoView from 'react-scroll-into-view';
import { Navbar } from './Navbar';
import { CartaNatal } from './consultas/CartaNatal';
import { RevolucionSolar } from './consultas/RevolucionSolar';
import { Sinastria } from './consultas/Sinastria';
import { Footer } from './Footer';



export const Consultas = () => {
    return (
        <>
        <Navbar/>
        <div className='animate__animated animate__fadeIn'>
        
            <div className='consultas__titulo'>
                
                <h4 className="focus-in-contract-bck">CONSULTAS</h4>
                    
            </div>
            <div className='consultas__contenedor  focus-in-contract-bck'> 
                    
                <div className='consultas__servicio'>
                    <img src='./assets/consultasCN1.webp'
                        className='img1'
                        alt='consulta1'/>
                    <div className="middle">
                    
                        <ScrollIntoView selector="#cartanatal">
                          <button className="mdl-button mdl-js-button mdl-button--raised">
                            Carta Natal
                          </button>
                        </ScrollIntoView>
                    
                    </div>
                </div>
                <div className='consultas__servicio'>
                    <img src='./assets/consultasRV1.webp'
                        className='img2'
                        alt='consulta2'/>
                    <div className="middle">
                    
                        <ScrollIntoView selector="#revSolar">
                          <button className="mdl-button mdl-js-button mdl-button--raised">
                          Revolución Solar + Transitos
                          </button>
                        </ScrollIntoView>

                    </div>
                </div>
                    
                <div className='consultas__servicio'>
                    <img src='./assets/consultasS1.webp'
                        className='img3'
                        alt='consulta3'/>
                    <div className="middle">

                            <ScrollIntoView selector="#sinastria">
                              <button className="mdl-button mdl-js-button mdl-button--raised">
                                Sinastría
                              </button>
                            </ScrollIntoView>

                    </div>
                </div>
            </div>
        </div>
        <CartaNatal/>
        
        <RevolucionSolar/>
        
        <Sinastria/>

        <Footer/>
        
        </>
    )
}
