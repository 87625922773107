import React from 'react';
import { BackSide } from "three";

export const Escenario = () => {
    return (
        <mesh>
            <sphereBufferGeometry args={[0.1, 10, 10]} attach="geometry" />
            <meshStandardMaterial
              color={0xffffff}
              attach="material"
              side={BackSide}
              metalness={0.7}
            />
        </mesh>
    )
}
