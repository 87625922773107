import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
  } from "react-router-dom";
import { Consultas } from '../components/Consultas';
import { Contacto } from '../components/Contacto';
import { Introduccion } from '../components/Introduccion';
import { Main } from '../components/Main';
import { Privacidad } from '../components/Privacidad';
import { SobreMi } from '../components/SobreMi';
import { Terminos } from '../components/Terminos';
import { AppRouterITA } from './AppRouterITA';

export const AppRouter = () => {

    
    return (
        <Router>
            
            <div>
            
                <Switch>
                    <Route exact path="/astrviva" component={ Introduccion }/>
                    <Route exact path="/consultas" component={ Consultas }/>
                    <Route exact path="/about" component={ SobreMi } />
                    <Route exact path="/contacto" component={ Contacto } />
                    <Route exact path="/privacidad" component={ Privacidad } />
                    <Route exact path="/termycond" component={ Terminos } />
                     
                    <Route exact path="/ITA" component={ AppRouterITA }/>
                    <Route exact path="/" component={ Main }/>

                  <Redirect to='/'/>
                  
                </Switch>
      
            </div>
        </Router>
    )
}
