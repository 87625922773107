import React from 'react'
import { Footer } from './Footer'
import { Navbar } from './Navbar'

export const Privacidad = () => {
    return (
        <>
        <Navbar/>
        <div className='contacto__banner fade-in' >
            <div className='contacto__contenido-banner'></div>
        </div>
        <section className='legal__texto fade-in'>
            <h3>Introducción</h3>
            <p>Esta Política de Privacidad explica lo que hacemos con tus datos personales para prestar los servicios ofrecidos en la web Micromundos (la “Plataforma”), 
                como 
                {/* la venta y suscripción a cursos, la creación de tu perfil en el Sitio,  */}
                comunicaciones. 
                Esta política también explica los datos que podemos recopilar cuando visitas la Plataforma.

                Para poder registrarte y navegar por la Plataforma, es necesario que seas mayor de 18 años. 
                MICROMUNDOS puede utilizar tu información personal para verificar tu edad y asegurar el cumplimiento de esta restricción.</p>
            <h3>Base legal para el procesamiento de los datos personales</h3>
            <p>La recopilación y el procesamiento de tus datos personales se basa principalmente en la relación contractual y no contractual que tienes con nosotros.
                Cuando te enviamos boletines informativos y Servicios o cuando utilizamos la dirección de e-mail que nos has proporcionado para ponernos en contacto contigo de forma individual o para reconocerte cuando vuelves a visitar nuestra página web, lo hacemos bajo consentimiento informado.
                La manera en que procesamos la información recopilada por nuestras cookies se basa en el consentimiento informado que nos has dado, de acuerdo con la legislación vigente.</p>
            <h3>Información personal que recopilamos</h3>
            <p>Para registrarte en la Plataforma e identificarte, te pediremos tu dirección de e-mail y tu fecha de nacimiento.
                Para crear tu perfil en la Plataforma, puedes incluir información personal como el país o la ciudad en la que resides, una fotografía, tu nombre y tus apellidos, 
                tu formación, tu experiencia profesional y cualquier otro dato que quieras compartir en tu perfil. 
                Sin embargo, si no quieres que esta información sea pública, será suficiente con no rellenar tu perfil o borrar la información que hayas proporcionado.

                {/* Para comprar o suscribirte a nuestros cursos, te pediremos información de tu tarjeta de crédito o débito, incluidos la fecha de validez y el código CVV.  */}
                Si deseas pagar a través de PayPal, te pediremos la dirección de e-mail que tengas asociada a tu cuenta. Si deseas pagar con Mercado Pago o Stripe, 
                serás redirigido a la plataforma de pago. Si solicitas una factura, te pediremos tus datos de facturación si fuera necesario.

                {/* Cuando visitas o utilizas nuestros Servicios, registramos datos como la frecuencia de uso, la fecha y la hora en las que accedes, 
                cuándo ves o haces clic en un contenido específico, tus preferencias de cursos, etc. Utilizamos esa información cuando inicias sesión y cuando lees nuestros e-mails, 
                y utilizamos las direcciones de protocolos de internet (“IP”) para identificarte y registrar el uso que haces de la Plataforma */}

                No tomamos decisiones en función de los perfiles, más allá de la personalización de la publicidad y para la legítima prevención del fraude en internet.</p>
            <h3>Derechos de protección de datos</h3>
            <p>Como Controlador de datos, nos comprometemos a respetar la naturaleza confidencial de tus datos personales y garantizamos el completo ejercicio de estos derechos. En todo momento, podrás ejercer los siguientes derechos:
                <li>
                    Derecho al acceso. Tienes derecho a confirmar si estamos procesando tus datos personales y, si lo estamos, tienes derecho a obtener una copia de los datos y de la información relacionada con el procesamiento.
                </li>
                <li>
                    Derecho de modificación. Tienes derecho a corregir errores, a modificar datos incompletos o erróneos y a garantizar la autenticidad de la información procesada.
                </li>
                <li>
                    Derecho a eliminar. Tienes derecho a solicitar la eliminación de tus datos personales sin un retraso injustificado, en el caso de que hayan sido procesados de manera errónea o si el fin para el que han sido procesados o recopilados ha dejado de existir.
                </li>
                <li>
                    Derecho a limitar el procesamiento. Tienes derecho a solicitar la suspensión del procesamiento de tu información si es ilegal o si la exactitud de los datos es cuestionada.
                </li>
                <li>
                    Derecho de oposición. Tienes derecho a oponerte al procesamiento de tus datos cuando han sido utilizados con fines de marketing directo o cuando el procesamiento deba finalizar debido a un problema personal, excepto cuando haya legítimo interés o sea necesario para el ejercicio o la defensa de acciones.
                </li>
                <li>
                    Derecho a no ser sometido a decisiones personalizadas. Tienes derecho a no ser sometido a una decisión basada únicamente en el procesamiento automático de tus datos, incluida la caracterización del perfil, con efectos legales vinculantes o que te afecten, excepto cuando sea necesario para la ejecución de un contrato, de acuerdo con la ley, o si lo has autorizado de forma expresa.
                </li>
                Para poder ejercer estos derechos, envía un e-mail a contacto@micromundos.org indicando tu (i) identidad con el nombre completo y la dirección de e-mail utilizada para registrarte, un documento identificativo para comprobar tu identidad y (ii) el derecho o los derechos que estás ejerciendo.</p>
            <h3>Información sobre cookies</h3>
            <p>Utilizamos cookies, pequeños archivos con información relativa a tu navegación en esta Plataforma, principalmente para posibilitar una mejor experiencia del usuario. Por favor, lee nuestra Política de Cookies para obtener más información sobre las cookies que utilizamos, su finalidad y otra información de utilidad.</p>
            <h3>Cambios</h3>
            <p>Actualizaremos esta Política de Privacidad ocasionalmente y de acuerdo con las modificaciones legales, regulatorias u operativas. Te notificaremos estos cambios (incluida la fecha de vigencia) según las exigencias legales.</p>
        </section>
        <Footer/>
        </>
    )
}
export default Privacidad
